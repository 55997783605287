import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PageNavigationComponent } from './page-navigation/page-navigation.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ContentViewerComponent } from './content-viewer/content-viewer.component';

@NgModule({
    declarations: [ PageNavigationComponent, ContentViewerComponent ],
    imports: [ 
        CommonModule, 
        FormsModule,
        RouterModule,
        TranslateModule
    ],
    exports: [ PageNavigationComponent, ContentViewerComponent ],
    providers: [],
})
export class ComponentsModule {}