import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-viewer',
  template: '',
})
export class ContentViewerComponent {
  private hostElement: HTMLElement;
  
  constructor(
    elementRef: ElementRef,
    ) {
    this.hostElement = elementRef.nativeElement;
  } 
  
  @Input() set content(content) {
    if (content) {
      this.build(content);
    }
  }
  
  private build(content) {
    this.hostElement.innerHTML = content || '';

    if (!content) { return; }
  }

}
