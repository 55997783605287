import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'qoljangi';
  loadAPI: Promise<any>;

  ngOnInit() {
    this.loadAPI = new Promise((resolve) => {
      // this.loadScript();
      resolve(true);
    });
  }

  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }
    if (!isFound) {
      var dynamicScripts = [
        'assets/js/plugins.min.js',
        'assets/js/plugins.infinitescroll.js',
        'assets/js/functions.js',
        'assets/js/plugins.easing.js',
        'assets/js/plugins.lightbox.js',
        'assets/js/plugins.fitvids.js',
        'assets/js/plugins.counter.js',
        'assets/js/plugins.instagram.js',
        'assets/js/plugins.carousel.js',
        'assets/js/plugins.stickysidebar.js',
        'assets/include/rs-plugin/js/jquery.themepunch.tools.min.js',
        'assets/include/rs-plugin/js/jquery.themepunch.revolution.min.js',
        'assets/include/rs-plugin/js/extensions/revolution.extension.actions.min.js',
        'assets/include/rs-plugin/js/extensions/revolution.extension.carousel.min.js',
        'assets/include/rs-plugin/js/extensions/revolution.extension.kenburn.min.js',
        'assets/include/rs-plugin/js/extensions/revolution.extension.layeranimation.min.js',
        'assets/include/rs-plugin/js/extensions/revolution.extension.migration.min.js',
        'assets/include/rs-plugin/js/extensions/revolution.extension.navigation.min.js',
        'assets/include/rs-plugin/js/extensions/revolution.extension.parallax.min.js',
        'assets/include/rs-plugin/js/extensions/revolution.extension.slideanims.min.js',
        'assets/include/rs-plugin/js/extensions/revolution.extension.video.min.js',
        'assets/include/rs-plugin/js/custom.js',
        'assets/js/custom-calendar.js',
      ];
      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }
    }
  }
}
