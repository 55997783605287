import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from './components/components.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PreloaderComponent } from './preloader/preloader.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [PreloaderComponent],
    imports: [ 
        CommonModule,
        ComponentsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'uz',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }), 
    ],
    exports: [ TranslateModule, ComponentsModule, PreloaderComponent ],
    providers: [],
})
export class SharedModule {
    
    constructor(public translateService: TranslateService) {

        // this language will be used as a fallback when a translation isn't found in the current language
        this.translateService.setDefaultLang('uz');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        localStorage.setItem('lang','uz');
        this.translateService.use('uz');
        // translateService.use('ru');
    }
}