import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-navigation',
  templateUrl: './page-navigation.component.html',
  styleUrls: ['./page-navigation.component.scss']
})
export class PageNavigationComponent implements OnInit {  

  @Input() breadcrumbItems: Array<{}>;
  @Input() title: string;

  constructor() { }

  ngOnInit() {
  }

}
